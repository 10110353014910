import styled from 'styled-components'

const MapStyles = styled.div`
  path {
    stroke: ${props => props.stateBorderColor};
  }

  svg {
    width: 100%;
  }

  .michigan:hover {
    @media (min-width: ${props => props.theme.breakpoints.tablet}) {
      .selectable {
        fill: ${props => props.selectedStateColor};
      }
    }
  }
  
  .selectable {
    fill: ${props => props.selectableStateColor};

    &:hover {
      @media (min-width: ${props => props.theme.breakpoints.tablet}) {
        fill: ${props => props.selectedStateColor};
      }
    }

  }

  .selected {
    fill: ${props => props.selectedStateColor};
  }
`
export default MapStyles