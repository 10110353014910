import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';
import QRCode from 'react-qr-code';
import Styles from './BusinessCardHero.styles';
import Picture from '../Shared/Picture';
import GenerateQRCode from '../Shared/Icons/GenerateQRCode';
import theme from '../../styles/theme';
import MailWireframe from '../Shared/Icons/MailWireframe';
import Copy from '../Shared/Icons/Copy';
import sa from '../../styles/Components/SimpleAnimation';
import SMS from '../Shared/Icons/SMS';
import tooltip from '../Tooltip/Tooltip';

const BusinessCardHero = ({
  name = '',
  jobTitle = '',
  headerBackgroundColor = '',
  cardBackgroundColor = '',
  logoSrc = '/images/vmf-white-logo.png',
  logoAlt = 'Vanderbilt mortgage and finance logo.',
  headshotSrc = '',
  headshotAlt = '',
  individualNmls = '',
  streetAddress = '',
  city = '',
  state = '',
  zipCode = '',
  phone = '',
  email = '',
  messages = [''],
  signatureSrc = '',
  signatureAlt = '',
  applyNowLink = '',
}) => {
  const [qrCodeModalOpen, setQrCodeModalOpen] = useState(false);
  const location = useLocation();

  const openQrModal = () => setQrCodeModalOpen(true);
  const closeQrModal = () => setQrCodeModalOpen(false);

  const handleCopyClick = () => {
    if (navigator) {
      const textContent = `Contact Info for ${name}: NMLS #${individualNmls} ${streetAddress} ${city}, ${state} ${zipCode} ${phone} ${email}`;
      navigator.clipboard.writeText(textContent);
    }
  };

  return (
    <Styles
      headerBackgroundColor={headerBackgroundColor}
      cardBackgroundColor={cardBackgroundColor}
    >
      {qrCodeModalOpen && (
        <>
          <sa.div
            className='qr-modal'
            onClick={closeQrModal}
            delay={0}
            waitUntilInView={false}
          >
            <QRCode
              value={`https://vmf.com${location.pathname}`}
              size={158}
              viewBox='0 0 158 158'
            />
          </sa.div>
          <sa.div
            className='qr-modal-backdrop'
            onClick={closeQrModal}
            delay={0}
            waitUntilInView={false}
            shouldSlide={false}
          />
        </>
      )}
      <div className='apply-today-container'>
        <sa.a
          delay={0.2}
          className='dark-btn apply-today-btn-mobile'
          href={applyNowLink}
          target='_blank'
        >
          Apply Today
        </sa.a>
      </div>
      <Link to='/' className='title-section__logo' delay={0}>
        <Picture src={logoSrc} alt={logoAlt} />
      </Link>
      <div className='title-section' delay={0}>
        <div className='title-section__name-and-title'>
          <sa.h2 delay={0.2}>{name}</sa.h2>
          <sa.p delay={0.4}>{jobTitle}</sa.p>
        </div>
        <sa.div className='title-section__headshot-photo' delay={0.1}>
          <Picture src={headshotSrc} alt={headshotAlt} />
        </sa.div>
      </div>
      <div className='title-section__name-and-title-mobile'>
        <div className='title-section__name-and-button'>
          <sa.h2 delay={0.2}>{name}</sa.h2>
        </div>
        <sa.p delay={0.6}>{jobTitle}</sa.p>
      </div>
      <div className='info-and-messages'>
        <div className='info-section info-section--mobile'>
          <div className='nmls-line'>
            {individualNmls && <sa.p delay={0.8}>NMLS #{individualNmls}</sa.p>}
            <sa.button
              delay={0.4}
              className='icon-btn mobile-qr-code'
              onClick={openQrModal}
            >
              <GenerateQRCode color={theme.colors.babyBlue} />
            </sa.button>
          </div>
          <sa.p delay={0.9} className='street-address'>
            {streetAddress}
          </sa.p>
          <sa.p delay={1}>
            {city}, {state} {zipCode}
          </sa.p>
          <sa.a delay={1.1} href={`tel:${phone}`}>
            {phone}
          </sa.a>
          <sa.a delay={1.2} href={`mailto:${email}`}>
            {email}
          </sa.a>
        </div>
        <sa.div delay={0.8} className='info-section-desktop-wrapper'>
          <div className='info-section info-section--desktop'>
            {individualNmls && <sa.p delay={0.8}>NMLS #{individualNmls}</sa.p>}
            <sa.p delay={0.9} className='street-address'>
              {streetAddress}
            </sa.p>
            <sa.p delay={1}>
              {city}, {state} {zipCode}
            </sa.p>
            <sa.a delay={1.1} href={`tel:${phone}`}>
              {phone}
            </sa.a>
            <sa.a delay={1.2} href={`mailto:${email}`}>
              {email}
            </sa.a>
            <sa.div delay={1.4} className='send-copy-icon-buttons'>
              <tooltip.a
                className='icon-btn'
                target='_blank'
                href={`mailto:?subject=Contact Info: ${name}&body=https://vmf.com${location.pathname}`}
                tooltipText='Email Contact'
                showOnFocus={true}
                xPosition='right'
              >
                <MailWireframe colorClass='icon-btn-icon icon-btn-icon--focus' />
              </tooltip.a>
              <tooltip.button
                className='icon-btn'
                onClick={() => handleCopyClick()}
                tooltipText='Copy to Clipboard'
                showOnClick={false}
                showOnHover={true}
                showOnFocus={true}
                xPosition='right'
              >
                <Copy colorClass='icon-btn-icon icon-btn-icon--focus' />
              </tooltip.button>
            </sa.div>
          </div>
          <tooltip.button
            className='icon-btn'
            onClick={openQrModal}
            tooltipText='View QR Code'
            showOnFocus={true}
            xPosition='center'
          >
            <GenerateQRCode colorClass='icon-btn-icon icon-btn-icon--focus' />
          </tooltip.button>
        </sa.div>
        <div className='button-section-mobile'>
          <div className='row contact-menu'>
            <sa.a
              delay={1.6}
              className='icon-btn'
              target='_blank'
              href={`mailto:?subject=Contact Info: ${name}&body=https://vmf.com${location.pathname}`}
            >
              <MailWireframe
                className='icon'
                size={30}
                colorClass='icon-btn-icon'
              />
              Email
            </sa.a>
            <sa.button
              delay={1.7}
              className='icon-btn'
              onClick={() => {
                handleCopyClick();
              }}
            >
              <tooltip.div
                tooltipText='Copied to Clipboard'
                showOnClick={true}
                showOnHover={false}
                xPosition='center'
                tooltipDuration={2000}
              >
                <Copy className='icon' size={30} colorClass='icon-btn-icon' />
              </tooltip.div>
              Copy
            </sa.button>
            <sa.a
              delay={1.8}
              className='icon-btn'
              target='_blank'
              href={`sms:?&body=Contact Info for ${name}: NMLS %23${individualNmls} ${streetAddress} ${city}, ${state} ${zipCode} ${phone} ${email}`}
            >
              <SMS className='icon' strokeClass='icon-btn-icon-stroke' />
              SMS
            </sa.a>
          </div>
        </div>
        <div className='messages-section'>
          {messages.map((message, i, a) => (
            <sa.p
              delay={2 + (0.2 * i + 0.2)}
              className='message'
              key={`messages-${i}`}
            >
              {message}
              {i === a.length - 1 ? (
                signatureSrc && (
                  <Picture
                    className='signature signature--desktop'
                    src={signatureSrc}
                    alt={signatureAlt}
                  />
                )
              ) : (
                <></>
              )}
            </sa.p>
          ))}
          {signatureSrc && (
            <Picture
              className='signature signature--mobile'
              src={signatureSrc}
              alt={signatureAlt}
            />
          )}
        </div>
      </div>
    </Styles>
  );
};

BusinessCardHero.propTypes = {
  name: PropTypes.string,
  jobTitle: PropTypes.string,
  headerBackgroundColor: PropTypes.string,
  cardBackgroundColor: PropTypes.string,
  logoSrc: PropTypes.string,
  logoAlt: PropTypes.string,
  headshotSrc: PropTypes.string,
  headshotAlt: PropTypes.string,
  individualNmls: PropTypes.string,
  streetAddress: PropTypes.string,
  city: PropTypes.string,
  state: PropTypes.string,
  zipCode: PropTypes.string,
  phone: PropTypes.string,
};

export default BusinessCardHero;
