import styled from 'styled-components';

export default styled.section`
  background-color: ${props => props.cardBackgroundColor};
  padding-bottom: 1.3125rem;
  position: relative;
  z-index: 400;

  .apply-today-container {
    width: 100vw;
    position: fixed;
    z-index: 800;
    bottom: 2rem;
    display: flex;
    justify-content: center;
  }

  .apply-today-btn-mobile {
    display: block;
    max-width: 20.375rem;
    width: calc(100vw - (1.5625rem * 2));
    padding: 1rem;
    border-radius: 8px;
    font-family: nunito;
    font-size: 1rem;
    font-weight: 700;
    transition: all 0.2s;
    cursor: pointer;
    text-decoration: none;
  }

  .qr-modal {
    position: fixed;
    padding: 1.6875rem;
    background: #FFFFFF;
    box-shadow: 3px 3px 12px rgba(0, 0, 0, 0.1);
    z-index: 51;
    border-radius: 8px;
    top: 50%;
    left: 50%;
    translate: -50% -50%;
  }

  .qr-modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 50;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.4);
  }

  .dark-btn {
    background-color: ${props => props.theme.colors.vmfBlue};
    border: 1px solid ${props => props.theme.colors.vmfBlue};
    color: ${props => props.theme.colors.white};
    text-align: center;

    &:hover, &:active {
      background-color: ${props => props.theme.colors.white};
      border: 1px solid ${props => props.theme.colors.vmfBlue};
      color: ${props => props.theme.colors.vmfBlue};
    }
    
  }

  .contact-menu {
    display: flex;
    height: 5.625rem;
    width: 100vw;
    background-color: #FFFFFF;
    justify-content: space-evenly;

    button, a {
      text-decoration: none;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      color: ${props => props.theme.colors.vmfBlue};
      font-family: nunito;
      font-size: 0.875rem;
      font-weight: 700;
      padding: 0 2rem;

      .icon {
        margin-bottom: 0.6875rem;
      }
      
    }
    
  }

  * {
    box-sizing: border-box;
  }

  h1, h2, h3, h4, h5, h6, p {
    margin: 0;
  }

  .title-section {
    background-color: ${props => props.headerBackgroundColor};
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 11rem;
    position: relative;
    /* padding: 0rem ${props => props.theme.padding.inner}; */

    * {
      color: ${props => props.theme.colors.white};
    }

    &__name-and-title {
      flex-direction: column;
      h2, p {
        text-align: center;
      }
      
      h2 {
        margin-top: 3.125rem;
        font-size: 2.5rem;
        font-weight: 700;
        letter-spacing: -1px;
      }

      p {
        font-size: 1.125rem;
        font-weight: 400;
        font-family: futura-pt;
      }
      
    }

    &__headshot-photo {
      border-radius: 100%;
      overflow: hidden;
      width: 11.875rem;
      height: 11.875rem;
      position: absolute;
      bottom: -10rem;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

      picture {
        width: 100%;
        height: 100%;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
        }
      } 
    }

    &__logo {
      background-color: ${props => props.headerBackgroundColor};
      height: 6rem;
      width: 100%;
      display: flex;
      justify-content: center;
      padding-top: 1.1875rem;
      position: sticky;
      top: 0;
      z-index: 45;
      padding: .5rem;
    }

    &__name-and-title-mobile {
      padding: 0rem ${props => props.theme.padding.inner};
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 6.125rem;

      p {
        color: #357FAD;
        font-weight: 700;
        font-size: 1.25rem;
        letter-spacing: -0.25px;
        font-family: futura-pt;
      }
      
    }

    &__name-and-button {
      display: flex;
      align-items: center;

      h2 {
        font-size: 2.5rem;
        font-weight: 700;
        letter-spacing: -1px;
      }

      button {
        margin-left: 0.6875rem;
      }
      
    }

  }

  .info-section {
    display: flex;
    flex-direction: column;
    margin: auto;
    margin-top: 1.625rem;

    p, a {
      color: ${props => props.theme.colors.darkBlue};
      font-size: 1.125rem;
      font-weight: 400;
      font-family: nunito;
      margin-bottom: .8rem;

      &.street-address {
        margin-bottom: 0;
      }
    }

    &--mobile {
      max-width: 20.375rem;
      padding: 0rem ${props => props.theme.padding.inner};

      .nmls-line {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
      }
      
    }

    &--desktop {
      margin: 0;
    }
  }

  .info-section-desktop-wrapper {
    padding: 1.25rem 1.8125rem;
    background-color: ${props => props.theme.colors.grey6};
    display: flex;
    height: fit-content;
    margin-right: 2rem;

    > button {
      height: fit-content;
      margin-left: 2rem;
    }

    .send-copy-icon-buttons {
      display: flex;
      margin-top: 0.3669rem;

      button, a {
        margin: 0;
        margin-right: 1.5rem;

        &:last-child {
          margin-right: 0;
        }
        
      }
      
    }
  }

  .info-and-messages {
    max-width: 1179px;
    width: 100%;
    padding: 0rem ${props => props.theme.padding.inner};
    justify-content: space-between;
    margin: auto;
  }

  .button-section-mobile {
    margin: 3.875rem auto 3.6875rem;
    display: flex;
    flex-direction: column;
    max-width: 25rem;
    width: 100%;

    .row {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-bottom: 0.625rem;

      &:last-of-type {
        margin-bottom: 0;
      }
      
      button, a {
        width: inherit;
        margin-left: 0.375rem;
        padding: 1rem;
        border-radius: 8px;
        font-family: nunito;
        font-size: 1rem;
        font-weight: 700;
        transition: all 0.2s;
        cursor: pointer;
        text-decoration: none;

        &:first-of-type {
          margin-left: 0;
        }

        &.light-btn {
          background-color: ${props => props.theme.colors.white};
          border: 1px solid ${props => props.theme.colors.vmfBlue};
          color: ${props => props.theme.colors.vmfBlue};

          &:hover, &:active {
            background-color: ${props => props.theme.colors.vmfBlue};
            border: 1px solid ${props => props.theme.colors.vmfBlue};
            color: ${props => props.theme.colors.white};
          }
          
        }
      }
    }
    
  }

  .messages-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 47.625rem;

    .message {
      margin-bottom: 1rem;
    }

    .signature {
      max-width: 14.6875rem;

      img {
        width: 100%;
      }

      &--desktop {
        float: right;
        position: relative;
        margin-right: 15%;
      }
      
    }
    
  }

  .signature {
    display: none;
  }

  .icon-btn {
    display: inline-block;
    border: none;
    background: none;
    cursor: pointer;
    padding: 0;

    .icon-btn-icon {
      fill: ${props => props.theme.colors.vmfBlue};
      transition: fill 0.2s;
    }

    .icon-btn-icon-stroke {
      stroke: ${props => props.theme.colors.vmfBlue};
      transition: stroke 0.2s;
    }

    &:focus {
      .icon-btn-icon--focus {
        fill: ${props => props.theme.colors.babyBlue};
      }

      .icon-btn-icon-stroke--focus {
        stroke: ${props => props.theme.colors.babyBlue};
      }
    }
    
  }

  /* Desktop */
  @media (min-width: ${props => props.theme.breakpoints.tablet}) {
    .title-section__name-and-title-mobile,
    .title-section__logo,
    .info-section--mobile,
    .button-section-mobile,
    .contact-menu,
    .apply-today-container
    {
      display: none;
    }

    .title-section__name-and-title,
    .info-section-desktop-wrapper,
    .signature--desktop
    {
      display: flex;
    }

    .title-section {
      margin-bottom: 13.5rem;
    }

    .info-and-messages {
      display: flex;
    }
  }

  /* Mobile */
  @media (max-width: ${props => props.theme.breakpoints.tablet}) {
    .title-section__name-and-title-mobile,
    .title-section__logo,
    .info-section--mobile,
    .button-section-mobile,
    .signature--mobile,
    .apply-today-container
    {
      display: flex;
    }

    .title-section__name-and-title,
    .info-section-desktop-wrapper,
    .signature--desktop
    {
      display: none;
    }

    .title-section {
      height: 7rem;

      &__headshot-photo {
        position: absolute;
        bottom: -5.5rem;
      }
      
    }
  }
  
`;
