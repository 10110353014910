import React, { useEffect, useMemo } from 'react';
import Styles from './BusinessCard.styled';
import ErrorComponent from '../../components/Error/Error';
import { getLpTemplate, getBusinessCard } from '../../services/contentful';
import { loadModel } from 'vmfcom-lib/utils';
import Layout from '../../components/Layout/Layout';
import SEO from '../../components/SEO/SEO';
import Loading from '../../components/Loading/Loading';
import BusinessCardHero from '../../components/BusinessCardHero/BusinessCardHero';
import { getHexValuesInString } from '../../util/colors';
import { updateHeaderLink, useAppContext } from '../../contexts/AppContext';
import ReactMarkdown from 'react-markdown';
import MainContent from '../../components/Contentful/v2/MainContent/MainContent';

const BusinessCard = ({
  templateData,
  businessCardData,
  uid,
  error,
  isLoading,
  teamName,
  licensedStateColor,
}) => {
  if (error) {
    console.error(error);

    return (
      <Layout>
        <ErrorComponent />
      </Layout>
    );
  }
  const lpTemplate = loadModel(templateData, 'VMFCom', 'LpTemplate');

  const businessCard = loadModel(
    businessCardData,
    'VMFCom',
    'BusinessCardSection'
  );
  if (isLoading) return null;
  const [, dispatch] = useAppContext();

  const applyNowLink = useMemo(
    () =>
      `/applynow/welcome?ReferringTM=${decodeURIComponent(`${businessCardData?.email.toLowerCase() || ''}`)}${businessCardData?.team.includes('Portfolio') ? '&navigate=welcome' : ''}`,
    [uid, businessCardData?.email]
  );

  useEffect(() => {
    updateHeaderLink(dispatch, applyNowLink);
  }, [applyNowLink]);
  return (
    <Layout isLoading={isLoading}>
      <SEO
        title={lpTemplate?.titleTag}
        description={lpTemplate?.metaDescription}
      />
      <Loading isLoading={isLoading} />
      <Styles>
        <BusinessCardHero
          name={`${businessCard?.firstName} ${businessCard?.lastName}`}
          jobTitle={businessCard?.lastNjobTitleme}
          headerBackgroundColor={
            getHexValuesInString(businessCard?.cardHeaderBackgroundColor)?.[0]
          }
          cardBackgroundColor={
            getHexValuesInString(businessCard?.cardBackgroundColor)?.[0]
          }
          headshotSrc={businessCard?.headshotPhoto?.file?.url}
          headshotAlt={businessCard?.headshotPhoto?.description}
          individualNmls={businessCard?.individualNmls}
          streetAddress={businessCard?.streetAddress}
          city={businessCard?.city}
          state={businessCard?.state}
          zipCode={businessCard?.zipCode}
          phone={businessCard?.phoneNumber}
          email={businessCard?.email}
          messages={[
            businessCard?.teamMessage,
            <ReactMarkdown source={businessCard?.personalMessage} />,
          ]}
          signatureSrc={businessCard?.signatureImage?.file?.url}
          signatureAlt={businessCard?.signatureImage?.description}
          applyNowLink={applyNowLink}
        />

        <MainContent
          entries={lpTemplate?.mainContent}
          componentProps={{
            licenseMapSection: {
              isIndividual: true,
              businessCards: [businessCard],
              showTooltips: true,
            },
            cta: { className: 'business-card-cta' },
          }}
        />
      </Styles>
    </Layout>
  );
};

BusinessCard.getInitialProps = async ({ match }) => {
  if (typeof window !== 'undefined') {
    window.location.reload();
  } else {
    const { teamName, officerUID } = match.params;
    try {
      if (!officerUID) {
        throw 'officerUID not provided';
      }

      const lpTemplate = await getLpTemplate(`/${teamName}/team/`);

      const businessCard = await getBusinessCard(officerUID.toUpperCase());

      if (!lpTemplate || !businessCard) {
        return { redirectTo: '/' + teamName + '/' };
      }
      return {
        templateData: lpTemplate,
        businessCardData: businessCard,
        uid: officerUID,
      };
    } catch (error) {
      return {
        error,
      };
    }
  }
};

export default BusinessCard;
